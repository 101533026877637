import { firebase } from './firebase-loader'
import { setFirebase, setBindCollectionDefaults } from '@olibm/js1-vue/firestore/v1'

if (process.env.DEV) console.warn('init: src/boot/firebase import')

export { FirestoreCollectionHandler, RestCollectionHandler } from '@olibm/js1-vue/firestore/v1'
export { beginOnIdTokenChanged } from '@olibm/js1-vue/firebase/v1'

export { registerExpander, bindCollection } from '@olibm/js1-vue/firestore/v1'

setBindCollectionDefaults({
  queryParams: {
    limit: 50
  }
})

export default async function ({ app }) {
  if (process.env.DEV) console.warn('init: src/boot/firebase exec')
  // await new Promise(resolve => document.addEventListener('deviceready', resolve, false))

  const { getCachedPortalConfig } = await import('./rest')

  const { firebaseConfigs } = await getCachedPortalConfig()

  if (firebaseConfigs) {
    firebaseConfigs.forEach(({ options, name, firebaseAuthTenant }) => {
      if (process.env.DEV) console.log('firebase.initializeApp', { name, options })
      const app = firebase.initializeApp(options, name)

      if (firebaseAuthTenant) {
        app.auth().tenantId = firebaseAuthTenant // This is NOT our tenantId. This is the Google Identity Platform tenant id.
      }
    })
  }

  switch (app.__VUE_I18N__.global.locale.toLowerCase()) {
  // switch ('nb-no') {
    case 'nb-no': firebase.auth().languageCode = 'no'; break
    default: firebase.auth().languageCode = 'en'; break
  }

  setFirebase(firebase)
}
