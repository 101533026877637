
import { firebase } from './firebase-loader'
import { RestClient } from '@olibm/js1-vue/requestpv1'
import axios from 'axios'
import axiosRetry, { exponentialDelay } from 'axios-retry'

// TODO: untested
function isRetryableError (error) {
  const retryCodes = [429, 502, 503]
  return !!(
    (error.code && !error.response && error.code !== 'ECONNABORTED') ||
    (error.response && retryCodes.includes(error.response.status))
  )
}

axiosRetry(axios, {
  retries: 3,
  retryCondition: isRetryableError,
  retryDelay: x => exponentialDelay(x) * 3
})

const apiConfigs = {
  apiRouter: {
    baseUrl: process.env.API_ROUTER_URL,
    qs: {
      key: process.env.API_ROUTER_KEY
    },
    auth: {
      bearer: 'currentUser'
    },
    debugResponse: !!process.env.DEV
  }
}
if (process.env.DEV) console.log('apiConfigs', apiConfigs)

const apiRouterHeaders = {}
const apiRouterClient = createApiRouterClient()

export const apiRouter = apiRouterClient.make()

export default async function ({ app }) {
  app.config.globalProperties.$apiRouter = apiRouter
}

const cachedPortalConfigProm = Promise.resolve().then(async () => {
  const retryDelay = 1000
  let cfg
  while (!cfg) {
    cfg = await apiRouter.get('/xrm-tenants/v1/login/portalConfig', { auth: { bearer: null } }).catch(async err => {
      console.warn(`getCachedPortalConfig error. Retry in ${retryDelay / 1000} seconds`, err)
      // eslint-disable-next-line promise/param-names
      await new Promise(r => setTimeout(r, retryDelay))
    })
  }
  return cfg
})

export async function getCachedPortalConfig () {
  return await cachedPortalConfigProm
}

export function createApiRouterClient (preBuilder?) {
  const apiRouterClient = new RestClient(apiConfigs.apiRouter)
  if (preBuilder) {
    preBuilder(apiRouterClient)
  }
  apiRouterClient.preHook(apiRouterPreHook)
  return apiRouterClient
}

export async function getCurrentUserBearerToken (forceRefresh?: boolean) {
  const { currentUser } = firebase.auth()
  if (!currentUser) {
    return null
    // throw new Error('User not logged in')
  }
  return await currentUser.getIdToken(!!forceRefresh)
}

// let loggedBaseUrlTodoHack = false;
async function apiRouterPreHook ({ options }) {
  if (options.auth?.bearer === 'currentUser') {
    const { currentUser } = firebase.auth()
    if (!currentUser) {
      // throw new Error('User not logged in')
    }
    options.auth.bearer = await getCurrentUserBearerToken()
  }
  Object.assign(options.headers, apiRouterHeaders)
}
